.home-section{
  height:820px!important;
}

.home-section img{
  height:auto!important;
}


.centered-text-home {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2rem;
  width: 100%;
  text-align: center;
}

.centered-text-home p {
  font-size: 1.2rem;
  line-height: 2rem;
}

.centered-text-home .homebutton {
  margin-top: 40px!important;
  color: #ffffff!important;
  border: 1px solid #ffffff!important;
  padding: 10px 30px!important;
  font-size: 1.5rem!important;
  border-radius: 0px!important;
}

.centered-text-home .homebutton:hover {
  cursor: pointer;
  background-color: #ffffff;
  color: #000!important;
}

.landing-section {
  margin-top: 100px;
  text-align: center;
}

.landing-section h1 {
  text-align: center;
  color: #333333;
  line-height: 1.4em;
  font-size: 2.5em;
  letter-spacing: 0.1em;
  font-weight: 400;
}

.landing-section .decoration {
  line-height: 0em;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.landing-section .decoration .decoration-inside {
  height: 1px;
  display: inline-block;
  border-top: 1px solid #333333;
  width: 100%;
  max-width: 10%;
}

.h2-heading {
  color: #666666;
  font-weight: 600;
}

@media all and (max-width : 991px) {
  .home-section{
    height:800px!important;
  }
  
  .home-section img{
    height:100%!important;
  }

  .centered-text-home {
   font-size: 1rem;
   padding-top: 50px;
  }
  
  .centered-text-home p {
    font-size: 0.9rem;
  }

  .landing-section h1 {
    font-size: 2em;
  }

  .landing-section p {
    font-size: 1em;
    margin-inline: 20px;
  }

  
}
