/* LandingPage.css */

.landing-container {
  position: relative;
}

.image-container { 
  position: relative;
  overflow: hidden;
}

.image-container img {
  height: 720px;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.5);
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 50px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  width: 99%;
  text-align: center;
}

.centered-text h2{
  font-weight: 400;
}

.centered-text p {
  font-weight: 400;
}

@media all and (max-width : 991px) {
  .centered-text {
   font-size: 30px;
  }

  .image-container img {
    height: 650px;
  }
}